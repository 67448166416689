import React from 'react';

function Projects() {
  return (
    <section id="projects">
      <h2>Projects</h2>
      <ul>
        <li>Zerxis Limited</li>
        <li>Call Center Africa</li>
        <li>VII</li>
      </ul>
    </section>
  );
}

export default Projects;

