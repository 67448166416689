import React from 'react';

function Contact() {
  return (
    <section id="contact">
      <h2>Contact</h2>
      <p>Email: hello@patrick.co.ke</p>
    </section>
  );
}

export default Contact;

