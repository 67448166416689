function About() {
  return (
    <section id="about">
      <h2>About Me</h2>
      <p>
        I'm a software developer with a passion for building web applications.
        I enjoy learning new technologies and improving my skills.
      </p>
    </section>
  );
}

export default About;
